.specification__value {
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 1.2em;
}
.specification__label {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 0.85em;
}
.specification__divider {
    margin-top: 2em;
    margin-bottom: 2em;
}
.header {
    background-size: cover;
    height: 260px;
    min-height: 260px;
}
.videoResponsive {
    position: relative;
    padding-bottom: 56.25%; /** 16:9 aspect ratio */
    padding-top: 25px;
    height: 0;
    width: 100%;
    overflow: hidden;
}
.videoResponsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}