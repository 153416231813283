
* {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: "Rubik", Arial, sans-serif;
    background-color: #262933;
}

body {
    font-size: 14px;
    line-height: 1.4;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Gilroy", Helvetica, sans-serif;
}

.font-header {
    font-family: "Gilroy", Helvetica, sans-serif;
    font-weight: 500;
}

.font-highlight {
    font-family: "Gilroy", Helvetica, sans-serif;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 99;
}

a[role="button"] {
    text-decoration: none;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 12px;
        height: 12px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}
